import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../store';
import { IonIcon } from '@ionic/react';
import './NotificationPage.scss';
import { Notification } from '../../models/Notification';
import { RootState } from '../../models/RootState';
import API from '../../api';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import MicNoneIcon from '@material-ui/icons/MicNone';
import SVLS_API from '../../api-services/svls-api';

type StoreProps = {
  logout: Function;
  loggedIn: boolean;
};

const NotificationPage: React.FC<StoreProps> = (props) => {
  const { logout, loggedIn } = props;
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const location = useLocation();
  useEffect(() => {
    if (loggedIn) {
      fetchNotifications();
    }
  }, [loggedIn]);
  const fetchNotifications = async () => {
    const response = await SVLS_API.get('/catalog/v2/notifications/', {
      headers: {
        Authorization: sessionStorage.getItem('jwt_token'),
      },
      params: {
        type: 'ALL',
      }
    });

    setNotifications(response.data);
  };

  const getNotificationMessages = (notificationsList: Notification[]) => {
    let notfMsg = ' ';
    for (let i = 0; i < notificationsList.length; i++) {
      if (notificationsList[i].active) {
        notfMsg += ' ' + notificationsList[i].message;
        notfMsg += notificationsList[i + 1] ? '  ' + '  '.repeat(8) : '';
      }
    }

    return notfMsg;
  };

  return (
    <div className="notification">
      <div className="personal-tab-ctn">
        <div className="title">
          <div className="title-name">Announcement</div>
        </div>
        {notifications && notifications.length > 0 ? (
          notifications.map((itm, index) => {
            return (
              <>
                <div className="card11">
                  {location?.pathname === '/allAnnouncement' ? null : (
                    <div className="icon-ctn">
                      {' '}
                      <MicNoneIcon className="icon" />
                    </div>
                  )}
                  <div className="text1">
                    {moment(itm.startTime).format('DD-MM-YY ')} - {itm?.message}
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <div className="card11">
            <div className="icon-ctn">
              {' '}
              <MicNoneIcon className="icon" />
            </div>

            <div className="text1">No Notification</div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);
