import API from '../../api';
import {
  logout,
  getAccountPathFromToken,
  getSportsBookFromToken,
} from '../auth/authActions';

import {
  IS_ADMIN_REPORTS_URL,
  IS_ADMIN_RISKMGMT_URL,
  IS_HOUSE_URL,
  TOGGLE_DARK_MODE,
  FETCH_BETTING_CURRENCY_SUCCESS,
  FETCH_BETTING_CURRENCY_FAILED,
  CASINO_GAME_SELECTED,
  SET_DC_GAME_URL,
  SET_LIVESTREAM_URL,
  SET_PLAY_STREAM,
  SET_ALLOWED_CONFIG,
  ENABLE_COMMISSION,
  TRIGGER_FETCH_BALANCE,
  TRIGGER_FETCH_NOTIFICATIONS,
} from './commonActionTypes';

import { CurrencyDetails } from '../../models/CurrencyDetails';
import { getBettingCurrency } from '../../api-services/backend/user.service';
import moment from 'moment';

export const activateReportsTab = (value: boolean) => {
  return {
    type: IS_ADMIN_REPORTS_URL,
    payload: value,
  };
};

export const activateRiskMgmtTab = (value: boolean) => {
  return {
    type: IS_ADMIN_RISKMGMT_URL,
    payload: value,
  };
};

export const activateHouseTab = (value: boolean) => {
  return {
    type: IS_HOUSE_URL,
    payload: value,
  };
};

export const toggleDarkMode = (value: boolean) => {
  return {
    type: TOGGLE_DARK_MODE,
    payload: value,
  };
};

export const setPlayStream = (value: boolean) => {
  return {
    type: SET_PLAY_STREAM,
    payload: value,
  };
};

const fetchBettingCurrencySuccess = (currency: CurrencyDetails) => {
  return {
    type: FETCH_BETTING_CURRENCY_SUCCESS,
    payload: currency,
  };
};

const fetchBettingCurrencyFailed = () => {
  return {
    type: FETCH_BETTING_CURRENCY_FAILED,
  };
};

export const fetchBettingCurrency = () => {
  // return async (dispatch: Function) => {
  //   try {
  //     const response = await getBettingCurrency();
  //     dispatch(fetchBettingCurrencySuccess(response.data));
  //   } catch (err) {
  //     dispatch(fetchBettingCurrencyFailed());
  //     if (err.response && err.response.status === 401) {
  //       const token = sessionStorage.getItem('jwt_token');
  //       if (token) {
  //         dispatch(logout());
  //       }
  //     }
  //   }
  // };
};

export const casinoGameSelected = (casinoGame: {
  id: string;
  name: string;
}) => {
  return {
    type: CASINO_GAME_SELECTED,
    payload: casinoGame,
  };
};

export const setDcGameUrl = (url: string) => {
  return {
    type: SET_DC_GAME_URL,
    payload: url,
  };
};

export const setLivestreamUrl = (url: string) => {
  return {
    type: SET_LIVESTREAM_URL,
    payload: url,
  };
};

export const setAllowedConfig = (allowedConfig: number) => {
  return {
    type: SET_ALLOWED_CONFIG,
    payload: allowedConfig,
  };
};

export const enableCommission = (commission: boolean) => {
  return {
    type: ENABLE_COMMISSION,
    payload: commission,
  };
};

export const triggerFetchBalance = (eventTime: number) => {
  return {
    type: TRIGGER_FETCH_BALANCE,
    payload: eventTime,
  };
};

export const triggerFetchNotifications = (data) => {
  var adminAccountPath = data.adminAccountPath.concat('/');
  if (
    getAccountPathFromToken().includes(adminAccountPath) &&
    (data.sportsBooks.includes('all') ||
      data.sportsBooks.includes(getSportsBookFromToken()))
  ) {
    return {
      type: TRIGGER_FETCH_NOTIFICATIONS,
      payload: moment.now(),
    };
  }
};

export const isAccountPath = (limitKey: string) => {
  return !limitKey.includes('/CT/SPORTS');
};
