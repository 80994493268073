import { ExBetslip } from '../../models/ExBetslip';
import {
  ADD_EXCH_BET,
  EXCH_BET_ODDS_CHANGE,
  REMOVE_EXCH_BET,
  SET_EXCH_BET_STAKE,
  CLEAR_EXCHANGE_BETS,
  FETCH_OPEN_BETS_SUCCESS,
  CLEAR_SUCCESS_BETS,
  SET_BUTTON_VARIABLES,
  SET_BETTING_INPROGRESS,
  ADD_OPEN_BETS,
  BET_CANCEL_SUCCESS,
  ENABLE_ONE_CLICK_BETS,
  ADD_ONE_CLICK_AMOUNT,
  UPDATE_BET_SLIP,
  RESET_BETSLIP_ODDS_CHANGE_MSG,
} from './exchBetslipActionTypes';
import { BUTTON_VARIABLES } from '../../constants/ButtonVariables';
import { _findIndexByArray } from '../../util/stringUtil';
import store from '../store';

type Action = {
  type: string;
  payload: any;
};

const initialState: ExBetslip = {
  bets: [],
  openBets: [],
  totalOrders: 0,
  buttonVariables: BUTTON_VARIABLES,
  bettingInprogress: false,
};

const ExchBetslipReducer = (
  state = initialState,
  action: Action
): ExBetslip => {
  switch (action.type) {
    case ADD_EXCH_BET: {
      const bettingInprogress = state.bettingInprogress;
      if (!bettingInprogress) {
        const bets = [...state.bets];
        bets.push(action.payload);
        return {
          ...state,
          bets,
        };
      }
      return {
        ...state,
      };
    }
    case EXCH_BET_ODDS_CHANGE: {
      const bets = [...state.bets];
      if (bets[action?.payload?.index]) {
        bets[action?.payload?.index].oddValue = action.payload.odds
          ? action.payload.odds
          : null;
      }
      // bets[action?.payload?.index].oddsPrice = action.payload.odds
      //   ? action.payload.odds
      //   : null;

      return {
        ...state,
        bets,
      };
    }
    case REMOVE_EXCH_BET: {
      const bets = [...state.bets];
      bets.splice(action.payload.index, 1);

      return {
        ...state,
        bets,
      };
    }
    case SET_EXCH_BET_STAKE: {
      const bets = [...state.bets];
      if (action.payload.type === 'ADD') {
        bets[action.payload.index].amount += action.payload.amount;
      } else if (action.payload.type === 'SET') {
        bets[action.payload.index].amount = action.payload.amount;
      }

      if (isNaN(bets[action.payload.index].amount)) {
        bets[action.payload.index].amount = 0;
      }

      return {
        ...state,
        bets,
      };
    }
    case CLEAR_EXCHANGE_BETS: {
      const bettingInprogress = state.bettingInprogress;
      if (!bettingInprogress) {
        return {
          ...state,
          bets: [],
        };
      }
      return {
        ...state,
      };
    }
    case SET_BUTTON_VARIABLES: {
      const btnVars = action.payload;
      return {
        ...state,
        buttonVariables: btnVars.length > 0 ? btnVars : BUTTON_VARIABLES,
      };
    }

    case FETCH_OPEN_BETS_SUCCESS: {
      const openBets = action.payload ? action.payload.result : [];
      const totalOrders = action.payload ? action.payload.totalOrders : 0;

      return {
        ...state,
        openBets: openBets,
        totalOrders: totalOrders,
      };
    }

    // case BET_CANCEL_SUCCESS: {
    //   let openBets = [...state.openBets];
    //   if (openBets && openBets?.length > 0) {
    //     for (let bet of openBets) {
    //       if (bet.betId === action.payload.betId && bet.unmatched) {
    //         const index = _findIndexByArray(openBets, action.payload.betId);
    //         if (index > -1) {
    //           openBets.splice(index, 1);
    //         }
    //       }
    //     }
    //   }

    //   return {
    //     ...state,
    //     openBets: openBets,
    //   };
    // }

    case ADD_OPEN_BETS: {
      const openBets = action.payload ? action.payload : [];
      const opBets = [...state.openBets];
      const allOpenBets = opBets.concat(openBets);
      return {
        ...state,
        openBets: allOpenBets,
      };
    }

    case SET_BETTING_INPROGRESS: {
      return {
        ...state,
        bettingInprogress: action.payload,
      };
    }

    case UPDATE_BET_SLIP: {
      const bets = state?.bets;

      if (bets.length > 0) {
        const eventId: string = action.payload.eventId;
        const bettingInprogress = state?.bettingInprogress;
        const matchOdds = action.payload.matchOddsData;

        if (bettingInprogress) {
          bets[0].isOddsUpdated = false;
          return;
        }
        if (action?.payload?.matchOddsData) {
          for (let bet of bets) {
            if (
              bet?.marketType === 'MO' &&
              bet?.eventId === eventId &&
              bet?.marketId === matchOdds.marketId
            ) {
              for (let mo of matchOdds.runners) {
                if (mo.runnerId === bet.outcomeId) {
                  if (bet.betType === 'BACK') {
                    if (bet.oddValue !== mo?.backPrices[bet.oddIndex]?.price)
                      bet.isOddsUpdated = true;
                    bet.oddValue = mo?.backPrices[bet.oddIndex]?.price;
                  } else {
                    if (bet.oddValue !== mo?.layPrices[bet.oddIndex]?.price) {
                      bet.isOddsUpdated = true;
                      bet.oddValue = mo?.layPrices[bet?.oddIndex]?.price;
                    }
                  }
                }
              }
            }
          }
        }

        return {
          ...state,
          bets: [...bets],
        };
      }
    }

    case CLEAR_SUCCESS_BETS: {
      // const currentBets = [...state.bets];
      // const successIndices: number[] = action.payload;

      // const filteredBets = currentBets.filter(
      //   (_, i) => !successIndices.includes(i)
      // );

      return {
        ...state,
        bets: [],
      };
    }

    case RESET_BETSLIP_ODDS_CHANGE_MSG: {
      return {
        ...state,
        // oddsChanged: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default ExchBetslipReducer;
