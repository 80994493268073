import { AxiosResponse } from 'axios';
import API from '../../api';
import { BsData } from '../../models/BsData';
import { ButtonVariable } from '../../models/ButtonVariables';
import {
  ADD_EXCH_BET,
  EXCH_BET_ODDS_CHANGE,
  REMOVE_EXCH_BET,
  SET_EXCH_BET_STAKE,
  CLEAR_EXCHANGE_BETS,
  FETCH_OPEN_BETS_SUCCESS,
  CLEAR_SUCCESS_BETS,
  SET_BUTTON_VARIABLES,
  SET_BETTING_INPROGRESS,
  ADD_OPEN_BETS,
  BET_CANCEL_SUCCESS,
  ENABLE_ONE_CLICK_BETS,
  ADD_ONE_CLICK_AMOUNT,
  UPDATE_BET_SLIP,
  RESET_BETSLIP_ODDS_CHANGE_MSG,
  VALIDATE_BETSLIP_ODDS,
} from './exchBetslipActionTypes';
import REPORTING_API from '../../reporting-api';
import SVLS_API from '../../svls-api';

export const addExchangeBet = (data: BsData) => {
  return async (dispatch: Function) => {
    dispatch(clearExchcngeBets());
    dispatch(addBetHandler(data));
  };
};

export const enableOnclickBet = (isOneClickEnable) => {
  return {
    type: ENABLE_ONE_CLICK_BETS,
    payload: isOneClickEnable,
  };
};
export const addOnclickBetAmount = (amount: number) => {
  return {
    type: ADD_ONE_CLICK_AMOUNT,
    payload: amount,
  };
};

const addBetHandler = (data: BsData) => {
  return {
    type: ADD_EXCH_BET,
    payload: data,
  };
};

export const removeExchangeBet = (index: number) => {
  return {
    type: REMOVE_EXCH_BET,
    payload: index,
  };
};

export const setExchangeBetStake = (
  index: number,
  amount: number,
  type: 'ADD' | 'SET'
) => {
  return {
    type: SET_EXCH_BET_STAKE,
    payload: {
      index,
      amount,
      type,
    },
  };
};

export const exchangeBetOddsChange = (index: number, odds: number) => {
  return {
    type: EXCH_BET_ODDS_CHANGE,
    payload: { index, odds },
  };
};

export const clearExchcngeBets = () => {
  return {
    type: CLEAR_EXCHANGE_BETS,
    payload: [],
  };
};

export const fetchOpenBetsSuccess = (result, totalOrders?: number) => {
  return {
    type: FETCH_OPEN_BETS_SUCCESS,
    payload: {
      result: result,
      totalOrders: totalOrders ?? 0,
    },
  };
};


export const addOpenBets = (openBets) => {
  return {
    type: ADD_OPEN_BETS,
    payload: openBets,
  };
};

export const fetchOpenBets = (eventId: string[] | string, sportId?: string) => {
  return async (dispatch: Function) => {
    if (eventId) {
      try {
        REPORTING_API.get('/reports/v2/orders/:search', {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
            Accept: 'application/json',
          },
          params: {
            reportType: 'OPEN_ORDERS',
            status: 'Open',
            eventId: Array.isArray(eventId) ? eventId.join(',') : eventId,
            sportId: sportId,
          },
        })
          .then((response) => {
            dispatch(
              fetchOpenBetsSuccess(
                response.data.orders,
                response.data.totalOrders
              )
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (err) {
        dispatch(fetchOpenBetsSuccess([]));
      }
    }
  };
};

export const clearSuccessBets = (successIndices?: number[]) => {
  return {
    type: CLEAR_SUCCESS_BETS,
    payload: successIndices,
  };
};

export const cancelBetSuccess = (betID: string) => {
  return {
    type: BET_CANCEL_SUCCESS,
    payload: betID,
  };
};

export const fetchButtonVariables = () => {
  return async (dispatch: Function) => {
    try {
      const response: AxiosResponse<ButtonVariable[]> = await SVLS_API.get(
        '/catalog/v2/settings/favourite-stakes/users',
        {
          headers: {
            Authorization: sessionStorage.getItem('jwt_token'),
          },
        }
      );
      if (response) {
        let btnVars = [];
        for (let btnVar of response.data) {
          btnVars.push({
            label: btnVar.label,
            stake: btnVar.stake,
          });
        }
        dispatch(setButtonVariables(btnVars));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const setButtonVariables = (buttonVariables: ButtonVariable[]) => {
  return {
    type: SET_BUTTON_VARIABLES,
    payload: buttonVariables,
  };
};

export const setBettingInprogress = (val: boolean) => {
  return {
    type: SET_BETTING_INPROGRESS,
    payload: val,
  };
};

export const updateBetslipfromWS = (payload) => {
  return {
    type: UPDATE_BET_SLIP,
    payload: payload,
  };
};

export const resetOddsChangeMsg = (payload) => {
  return {
    type: RESET_BETSLIP_ODDS_CHANGE_MSG,
    payload: payload,
  };
};

export const validateOdds = (payload) => {
  return {
    type: VALIDATE_BETSLIP_ODDS,
    payload: payload,
  };
};
