import API from '../../api';
import SVLS_API from '../svls-api';

export const getDcToken = async () => {
  if (!sessionStorage.getItem('jwt_token')) {
    return await API.get('/dc/game/token');
  } else {
    return await API.get('/dc/game/token', {
      headers: {
        Authorization: sessionStorage.getItem('jwt_token'),
      },
    });
  }
};

export const getDcFilteredGames = async () => {
  return await API.get('/dc/filtered-games');
};

export const gapEnabledGames = async () => {
  try {
    return await SVLS_API.get('/catalog/v2/categories/indian-casino/games/', {
      params: {
        providerId: '*',
      },
      headers: {
        Authorization: sessionStorage.getItem('jwt_token'),
      },
    });
  } catch (err) {
    console.log(err);
  }
};

export const getDcGameUrl = async (params) => {
  return await API.post('/dc/game/url', params);
};

export const getDcV1GameUrl = async (params) => {
  return await API.post('/dc/v1.1/game/url', params);
};

//Indian Casino

export const fetchIndCasinoResultByMarket = async (payload) => {
  return await API.post(
    'indian-casino/result-by-mid',
    {},
    {
      headers: {
        Authorization: sessionStorage.getItem('jwt_token'),
      },
      params: payload,
    }
  );
};

export const fetchIndCasinoResultBySport = async (payload) => {
  return await API.post(
    '/indian-casino/mids-by-sport',
    {},
    {
      headers: {
        Authorization: sessionStorage.getItem('jwt_token'),
      },
      params: payload,
    }
  );
};

export const fetchIndCasinoGame = async (gameCode) => {
  return await API.get('/indian-casino/round', {
    headers: {
      Authorization: sessionStorage.getItem('jwt_token'),
    },
    params: {
      indianCasinoGameType: gameCode,
    },
  });
};
