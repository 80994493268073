import { IonSpinner } from '@ionic/react';
import React from 'react';
import TitleImg from '../../assets/images/brand/brand.svg';
import DimandLoadinImg from '../../assets/images/brand/loader.gif';
import { BRAND_NAME } from '../../constants/Branding';
import './LoadingPage.scss';

type LoadinProps = {
  casinoLoading?: boolean;
};

const LoadingPage: React.FC<LoadinProps> = (props) => {
  const { casinoLoading } = props;

  return (
    <div className="loading-page-ctn">
      <div className="item-row">
        {casinoLoading ? (
          <img
            src={DimandLoadinImg}
            className="casino-loading-img"
            alt={BRAND_NAME}
          />
        ) : null}
        {/* <img src={TitleImg} className="title-img" alt={BRAND_NAME} /> */}
      </div>
      <div className="item-row">
        <IonSpinner className="loading-page-spinner" name="bubbles" />
        {/* <i className="fa fa-spinner fa-spin fa-fw"></i> */}
      </div>
    </div>
  );
};

export default LoadingPage;
